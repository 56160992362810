// import * as Types from "./generated/types";
export { type Resources } from "./generated/resources";

//for length/area only
// export { Types };

///what above here is actually needed?
// import { default as LanguagePackEnUs } from "./generated/language-pack.en-US";
import { default as LanguagePackFrFr } from "./generated/language-pack.fr-FR";
import { ResourcesServer } from "./generated/resources.server";

export const generatedResources = {
  // "en-US": ResourcesServer(LanguagePackEnUs, "en-US"),
  "fr-FR": ResourcesServer(LanguagePackFrFr, "fr-FR"),
};
