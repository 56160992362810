import {ICUMessageFormatter} from "@costar/i18n"
import {Resources} from "./resources"
const createFormat = <T extends Record<string, any> | undefined>(icuMessage: string, culture: string) => {
    let icu: ICUMessageFormatter;
    return function(args: T) {
        if (!icu) {
            icu = new ICUMessageFormatter(icuMessage, culture);
        }
        return icu.format.call(icu, args);
    };
};
export const ResourcesServer = (languagePack: Record<string, string>, culture: any): Resources => ({
    /* areaPlaceholderWithNumber */ areaPlaceholderWithNumber: createFormat<any>(languagePack["areaPlaceholderWithNumber"], culture),
    /* imageAltText */ imageAltText: () => languagePack["imageAltText"],
    /* interviewsLabel */ interviewsLabel: () => languagePack["interviewsLabel"],
    /* convenient */ convenient: () => languagePack["convenient"],
    /* subscription */ subscription: () => languagePack["subscription"],
    /* advertisement */ advertisement: () => languagePack["advertisement"],
    /* whatAreWe */ whatAreWe: () => languagePack["whatAreWe"],
    /* legalMentions */ legalMentions: () => languagePack["legalMentions"],
    /* termsOfSales */ termsOfSales: () => languagePack["termsOfSales"],
    /* privacyPolicy */ privacyPolicy: () => languagePack["privacyPolicy"],
    /* cookiePolicy */ cookiePolicy: () => languagePack["cookiePolicy"],
    /* cookiePreferences */ cookiePreferences: () => languagePack["cookiePreferences"],
    /* copyrightMessage */ copyrightMessage: createFormat<any>(languagePack["copyrightMessage"], culture),
    /* news */ news: () => languagePack["news"],
    /* services */ services: () => languagePack["services"],
    /* editorial */ editorial: () => languagePack["editorial"],
    /* investigations */ investigations: () => languagePack["investigations"],
    /* pointsDeVue */ pointsDeVue: () => languagePack["pointsDeVue"],
    /* latestNewsLabel */ latestNewsLabel: () => languagePack["latestNewsLabel"],
    /* viewMore */ viewMore: () => languagePack["viewMore"],
    /* businessImmoTVAltText */ businessImmoTVAltText: () => languagePack["businessImmoTVAltText"],
    /* bigUpLogoAltText */ bigUpLogoAltText: () => languagePack["bigUpLogoAltText"],
    /* magazineAltText */ magazineAltText: () => languagePack["magazineAltText"],
    /* magazineSectionHeading */ magazineSectionHeading: () => languagePack["magazineSectionHeading"],
    /* magazineSectionViewMore */ magazineSectionViewMore: () => languagePack["magazineSectionViewMore"],
    /* mostReadSectionHeading */ mostReadSectionHeading: () => languagePack["mostReadSectionHeading"],
    /* moreNewsSectionHeading */ moreNewsSectionHeading: () => languagePack["moreNewsSectionHeading"],
    /* moreTransactionsNewsSectionHeading */ moreTransactionsNewsSectionHeading: () => languagePack["moreTransactionsNewsSectionHeading"],
    /* allNewsButton */ allNewsButton: () => languagePack["allNewsButton"],
    /* allTransactionsNewsButton */ allTransactionsNewsButton: () => languagePack["allTransactionsNewsButton"],
    /* setUpSectionHeading */ setUpSectionHeading: () => languagePack["setUpSectionHeading"],
    /* latestVideosSectionHeading */ latestVideosSectionHeading: () => languagePack["latestVideosSectionHeading"],
    article: {
        label: {
            /* article.label.edito */ edito: () => languagePack["article.label.edito"],
            /* article.label.magazine */ magazine: () => languagePack["article.label.magazine"],
            /* article.label.interview */ interview: () => languagePack["article.label.interview"],
            /* article.label.notebook */ notebook: () => languagePack["article.label.notebook"],
            /* article.label.pointDeVue */ pointDeVue: () => languagePack["article.label.pointDeVue"],
            /* article.label.bigUp */ bigUp: () => languagePack["article.label.bigUp"],
            /* article.label.portrait */ portrait: () => languagePack["article.label.portrait"]
        },
        relatedLinks: {
            /* article.relatedLinks.header */ header: () => languagePack["article.relatedLinks.header"],
            /* article.relatedLinks.properties */ properties: () => languagePack["article.relatedLinks.properties"],
            /* article.relatedLinks.markets */ markets: () => languagePack["article.relatedLinks.markets"],
            /* article.relatedLinks.companiesCited */ companiesCited: () => languagePack["article.relatedLinks.companiesCited"],
            /* article.relatedLinks.personsCited */ personsCited: () => languagePack["article.relatedLinks.personsCited"],
            /* article.relatedLinks.fileAttachments */ fileAttachments: () => languagePack["article.relatedLinks.fileAttachments"]
        },
        author: {
            /* article.author.bylineBy */ bylineBy: () => languagePack["article.author.bylineBy"]
        },
        shareIcon: {
            facebook: {
                /* article.shareIcon.facebook.title */ title: () => languagePack["article.shareIcon.facebook.title"]
            },
            twitter: {
                /* article.shareIcon.twitter.title */ title: () => languagePack["article.shareIcon.twitter.title"]
            },
            linkedin: {
                /* article.shareIcon.linkedin.title */ title: () => languagePack["article.shareIcon.linkedin.title"]
            },
            email: {
                /* article.shareIcon.email.title */ title: () => languagePack["article.shareIcon.email.title"]
            }
        }
    },
    /* logIn */ logIn: () => languagePack["logIn"],
    /* readAlso */ readAlso: () => languagePack["readAlso"],
    /* onTheSameTopic */ onTheSameTopic: () => languagePack["onTheSameTopic"],
    navigation: {
        /* navigation.allActualites */ allActualites: () => languagePack["navigation.allActualites"],
        /* navigation.allTransactions */ allTransactions: () => languagePack["navigation.allTransactions"],
        page: {
            /* navigation.page.actualites */ actualites: () => languagePack["navigation.page.actualites"],
            /* navigation.page.bio */ bio: () => languagePack["navigation.page.bio"],
            /* navigation.page.collaborateurs */ collaborateurs: () => languagePack["navigation.page.collaborateurs"],
            /* navigation.page.affiliations */ affiliations: () => languagePack["navigation.page.affiliations"],
            /* navigation.page.annuaire */ annuaire: () => languagePack["navigation.page.annuaire"],
            /* navigation.page.resume */ resume: () => languagePack["navigation.page.resume"],
            /* navigation.page.sites */ sites: () => languagePack["navigation.page.sites"],
            /* navigation.page.bitv */ bitv: () => languagePack["navigation.page.bitv"],
            /* navigation.page.transactions */ transactions: () => languagePack["navigation.page.transactions"],
            /* navigation.page.marketplace */ marketplace: () => languagePack["navigation.page.marketplace"]
        },
        news: {
            category: {
                /* navigation.news.category.evenements */ evenements: () => languagePack["navigation.news.category.evenements"],
                /* navigation.news.category.formations */ formations: () => languagePack["navigation.news.category.formations"],
                /* navigation.news.category.businessImmoSchool */ businessImmoSchool: () => languagePack["navigation.news.category.businessImmoSchool"],
                /* navigation.news.category.finance */ finance: () => languagePack["navigation.news.category.finance"],
                /* navigation.news.category.carrieres */ carrieres: () => languagePack["navigation.news.category.carrieres"],
                /* navigation.news.category.esg */ esg: () => languagePack["navigation.news.category.esg"],
                /* navigation.news.category.bureau */ bureau: () => languagePack["navigation.news.category.bureau"],
                /* navigation.news.category.commerce */ commerce: () => languagePack["navigation.news.category.commerce"],
                /* navigation.news.category.hotellerie */ hotellerie: () => languagePack["navigation.news.category.hotellerie"],
                /* navigation.news.category.logement */ logement: () => languagePack["navigation.news.category.logement"],
                /* navigation.news.category.logistique */ logistique: () => languagePack["navigation.news.category.logistique"],
                /* navigation.news.category.foncier */ foncier: () => languagePack["navigation.news.category.foncier"],
                /* navigation.news.category.alternative */ alternative: () => languagePack["navigation.news.category.alternative"],
                /* navigation.news.category.architecture */ architecture: () => languagePack["navigation.news.category.architecture"],
                /* navigation.news.category.designAndBuild */ designAndBuild: () => languagePack["navigation.news.category.designAndBuild"]
            }
        },
        transactions: {
            category: {
                /* navigation.transactions.category.bureau */ bureau: () => languagePack["navigation.transactions.category.bureau"],
                /* navigation.transactions.category.commerce */ commerce: () => languagePack["navigation.transactions.category.commerce"],
                /* navigation.transactions.category.hotellerie */ hotellerie: () => languagePack["navigation.transactions.category.hotellerie"],
                /* navigation.transactions.category.logement */ logement: () => languagePack["navigation.transactions.category.logement"],
                /* navigation.transactions.category.logistique */ logistique: () => languagePack["navigation.transactions.category.logistique"],
                /* navigation.transactions.category.foncier */ foncier: () => languagePack["navigation.transactions.category.foncier"],
                /* navigation.transactions.category.alternative */ alternative: () => languagePack["navigation.transactions.category.alternative"]
            }
        },
        group: {
            name: {
                /* navigation.group.name.servicesBusinessImmo */ servicesBusinessImmo: () => languagePack["navigation.group.name.servicesBusinessImmo"],
                /* navigation.group.name.monCompte */ monCompte: () => languagePack["navigation.group.name.monCompte"]
            }
        },
        link: {
            /* navigation.link.informationsPersonnelles */ informationsPersonnelles: () => languagePack["navigation.link.informationsPersonnelles"],
            /* navigation.link.motDePasse */ motDePasse: () => languagePack["navigation.link.motDePasse"],
            /* navigation.link.accountManager */ accountManager: () => languagePack["navigation.link.accountManager"],
            /* navigation.link.depechesEtNewsletters */ depechesEtNewsletters: () => languagePack["navigation.link.depechesEtNewsletters"],
            /* navigation.link.deconnexion */ deconnexion: () => languagePack["navigation.link.deconnexion"]
        },
        header: {
            link: {
                /* navigation.header.link.sabonner */ sabonner: () => languagePack["navigation.header.link.sabonner"]
            },
            search: {
                placeholder: {
                    /* navigation.header.search.placeholder.actualites */ actualites: () => languagePack["navigation.header.search.placeholder.actualites"],
                    /* navigation.header.search.placeholder.annuaire */ annuaire: () => languagePack["navigation.header.search.placeholder.annuaire"],
                    /* navigation.header.search.placeholder.societes */ societes: () => languagePack["navigation.header.search.placeholder.societes"]
                },
                /* navigation.header.search.buttonDescription */ buttonDescription: () => languagePack["navigation.header.search.buttonDescription"]
            }
        },
        professionals: {
            /* navigation.professionals.professionnels */ professionnels: () => languagePack["navigation.professionals.professionnels"],
            /* navigation.professionals.societes */ societes: () => languagePack["navigation.professionals.societes"]
        },
        loopnet: {
            /* navigation.loopnet.linkText */ linkText: () => languagePack["navigation.loopnet.linkText"]
        }
    },
    upsell: {
        login: {
            banner: {
                /* upsell.login.banner.text */ text: () => languagePack["upsell.login.banner.text"]
            }
        },
        header: {
            /* upsell.header.corporateSubscriber */ corporateSubscriber: () => languagePack["upsell.header.corporateSubscriber"],
            /* upsell.header.informationRequest */ informationRequest: () => languagePack["upsell.header.informationRequest"]
        },
        body: {
            /* upsell.body.headerTwo */ headerTwo: () => languagePack["upsell.body.headerTwo"],
            /* upsell.body.headerThree */ headerThree: () => languagePack["upsell.body.headerThree"],
            /* upsell.body.textOne */ textOne: () => languagePack["upsell.body.textOne"],
            /* upsell.body.textTwo */ textTwo: () => languagePack["upsell.body.textTwo"],
            /* upsell.body.textThree */ textThree: () => languagePack["upsell.body.textThree"],
            /* upsell.body.textFour */ textFour: () => languagePack["upsell.body.textFour"],
            /* upsell.body.learnMore */ learnMore: () => languagePack["upsell.body.learnMore"]
        },
        form: {
            submit: {
                /* upsell.form.submit.thankYou */ thankYou: () => languagePack["upsell.form.submit.thankYou"],
                /* upsell.form.submit.body */ body: () => languagePack["upsell.form.submit.body"],
                /* upsell.form.submit.errorText */ errorText: () => languagePack["upsell.form.submit.errorText"]
            },
            input: {
                placeholder: {
                    /* upsell.form.input.placeholder.companyName */ companyName: () => languagePack["upsell.form.input.placeholder.companyName"],
                    /* upsell.form.input.placeholder.firstName */ firstName: () => languagePack["upsell.form.input.placeholder.firstName"],
                    /* upsell.form.input.placeholder.lastName */ lastName: () => languagePack["upsell.form.input.placeholder.lastName"],
                    /* upsell.form.input.placeholder.emailAddress */ emailAddress: () => languagePack["upsell.form.input.placeholder.emailAddress"],
                    /* upsell.form.input.placeholder.phoneNumber */ phoneNumber: () => languagePack["upsell.form.input.placeholder.phoneNumber"],
                    /* upsell.form.input.placeholder.postalCode */ postalCode: () => languagePack["upsell.form.input.placeholder.postalCode"]
                }
            },
            dropdown: {
                placeholder: {
                    /* upsell.form.dropdown.placeholder.selectIndustry */ selectIndustry: () => languagePack["upsell.form.dropdown.placeholder.selectIndustry"]
                },
                option: {
                    /* upsell.form.dropdown.option.lender */ lender: () => languagePack["upsell.form.dropdown.option.lender"],
                    /* upsell.form.dropdown.option.multifamilyPropertyManager */ multifamilyPropertyManager: () => languagePack["upsell.form.dropdown.option.multifamilyPropertyManager"],
                    /* upsell.form.dropdown.option.broker */ broker: () => languagePack["upsell.form.dropdown.option.broker"],
                    /* upsell.form.dropdown.option.retailer */ retailer: () => languagePack["upsell.form.dropdown.option.retailer"],
                    /* upsell.form.dropdown.option.serviceProvider */ serviceProvider: () => languagePack["upsell.form.dropdown.option.serviceProvider"],
                    /* upsell.form.dropdown.option.ownerAndDeveloper */ ownerAndDeveloper: () => languagePack["upsell.form.dropdown.option.ownerAndDeveloper"],
                    /* upsell.form.dropdown.option.appraiser */ appraiser: () => languagePack["upsell.form.dropdown.option.appraiser"]
                }
            },
            button: {
                /* upsell.form.button.submit */ submit: () => languagePack["upsell.form.button.submit"]
            }
        },
        /* upsell.contactSearchBanner */ contactSearchBanner: () => languagePack["upsell.contactSearchBanner"],
        /* upsell.contactSearchFormDescription */ contactSearchFormDescription: () => languagePack["upsell.contactSearchFormDescription"],
        /* upsell.contactSearchColumn1Title */ contactSearchColumn1Title: () => languagePack["upsell.contactSearchColumn1Title"],
        /* upsell.contactSearchColumn1Body */ contactSearchColumn1Body: () => languagePack["upsell.contactSearchColumn1Body"]
    },
    common: {
        error: {
            /* common.error.problem */ problem: () => languagePack["common.error.problem"],
            /* common.error.info */ info: () => languagePack["common.error.info"],
            /* common.error.tryAgain */ tryAgain: () => languagePack["common.error.tryAgain"]
        },
        error500Msg: {
            /* common.error500Msg.heading */ heading: () => languagePack["common.error500Msg.heading"],
            /* common.error500Msg.info */ info: () => languagePack["common.error500Msg.info"],
            /* common.error500Msg.tryANewSearch */ tryANewSearch: () => languagePack["common.error500Msg.tryANewSearch"]
        },
        /* common.typeaheadFuzzySearch */ typeaheadFuzzySearch: createFormat<any>(languagePack["common.typeaheadFuzzySearch"], culture)
    },
    search: {
        results: {
            /* search.results.label */ label: createFormat<any>(languagePack["search.results.label"], culture)
        },
        thematiques: {
            /* search.thematiques.label */ label: () => languagePack["search.thematiques.label"],
            /* search.thematiques.finance */ finance: () => languagePack["search.thematiques.finance"],
            /* search.thematiques.carrieres */ carrieres: () => languagePack["search.thematiques.carrieres"],
            /* search.thematiques.ESG */ ESG: () => languagePack["search.thematiques.ESG"],
            /* search.thematiques.bureau */ bureau: () => languagePack["search.thematiques.bureau"],
            /* search.thematiques.commerce */ commerce: () => languagePack["search.thematiques.commerce"],
            /* search.thematiques.hotellerie */ hotellerie: () => languagePack["search.thematiques.hotellerie"],
            /* search.thematiques.logement */ logement: () => languagePack["search.thematiques.logement"],
            /* search.thematiques.logistique */ logistique: () => languagePack["search.thematiques.logistique"],
            /* search.thematiques.foncier */ foncier: () => languagePack["search.thematiques.foncier"],
            /* search.thematiques.alternative */ alternative: () => languagePack["search.thematiques.alternative"],
            /* search.thematiques.architecture */ architecture: () => languagePack["search.thematiques.architecture"],
            /* search.thematiques.designAndBuild */ designAndBuild: () => languagePack["search.thematiques.designAndBuild"],
            /* search.thematiques.location */ location: () => languagePack["search.thematiques.location"],
            /* search.thematiques.vente */ vente: () => languagePack["search.thematiques.vente"],
            /* search.thematiques.video */ video: () => languagePack["search.thematiques.video"]
        },
        categories: {
            /* search.categories.label */ label: () => languagePack["search.categories.label"],
            /* search.categories.transaction */ transaction: () => languagePack["search.categories.transaction"],
            /* search.categories.interview */ interview: () => languagePack["search.categories.interview"],
            /* search.categories.portrait */ portrait: () => languagePack["search.categories.portrait"],
            /* search.categories.edito */ edito: () => languagePack["search.categories.edito"],
            /* search.categories.pointDeVue */ pointDeVue: () => languagePack["search.categories.pointDeVue"],
            /* search.categories.carnet */ carnet: () => languagePack["search.categories.carnet"]
        },
        date: {
            /* search.date.label */ label: () => languagePack["search.date.label"],
            /* search.date.placeholder */ placeholder: () => languagePack["search.date.placeholder"],
            /* search.date.dividerText */ dividerText: () => languagePack["search.date.dividerText"],
            dropdown: {
                /* search.date.dropdown.label */ label: () => languagePack["search.date.dropdown.label"],
                /* search.date.dropdown.threeMonths */ threeMonths: () => languagePack["search.date.dropdown.threeMonths"],
                /* search.date.dropdown.sixMonths */ sixMonths: () => languagePack["search.date.dropdown.sixMonths"],
                /* search.date.dropdown.oneYear */ oneYear: () => languagePack["search.date.dropdown.oneYear"],
                /* search.date.dropdown.twoYears */ twoYears: () => languagePack["search.date.dropdown.twoYears"],
                /* search.date.dropdown.threeYears */ threeYears: () => languagePack["search.date.dropdown.threeYears"],
                /* search.date.dropdown.fourYears */ fourYears: () => languagePack["search.date.dropdown.fourYears"],
                /* search.date.dropdown.fiveYears */ fiveYears: () => languagePack["search.date.dropdown.fiveYears"],
                /* search.date.dropdown.tenYears */ tenYears: () => languagePack["search.date.dropdown.tenYears"]
            },
            /* search.date.before */ before: () => languagePack["search.date.before"],
            /* search.date.after */ after: () => languagePack["search.date.after"]
        },
        /* search.clear */ clear: () => languagePack["search.clear"],
        searchCards: {
            /* search.searchCards.clickHere */ clickHere: () => languagePack["search.searchCards.clickHere"],
            /* search.searchCards.foundInDirectory */ foundInDirectory: () => languagePack["search.searchCards.foundInDirectory"],
            /* search.searchCards.noAuth */ noAuth: () => languagePack["search.searchCards.noAuth"],
            /* search.searchCards.noResults */ noResults: () => languagePack["search.searchCards.noResults"]
        },
        /* search.filters */ filters: () => languagePack["search.filters"]
    },
    modal: {
        /* modal.OK */ OK: () => languagePack["modal.OK"]
    },
    cookieModal: {
        /* cookieModal.title */ title: () => languagePack["cookieModal.title"]
    },
    privatePolicyModal: {
        /* privatePolicyModal.title */ title: () => languagePack["privatePolicyModal.title"]
    },
    legalModal: {
        /* legalModal.title */ title: () => languagePack["legalModal.title"]
    },
    cgv: {
        /* cgv.title */ title: () => languagePack["cgv.title"]
    },
    directory: {
        /* directory.contactCount */ contactCount: createFormat<any>(languagePack["directory.contactCount"], culture),
        /* directory.companyCount */ companyCount: createFormat<any>(languagePack["directory.companyCount"], culture)
    },
    contacts: {
        grid: {
            /* contacts.grid.company */ company: () => languagePack["contacts.grid.company"],
            /* contacts.grid.title */ title: () => languagePack["contacts.grid.title"],
            /* contacts.grid.contactInfo */ contactInfo: () => languagePack["contacts.grid.contactInfo"]
        },
        filters: {
            contact: {
                /* contacts.filters.contact.placeholder */ placeholder: () => languagePack["contacts.filters.contact.placeholder"]
            }
        },
        card: {
            phone: {
                /* contacts.card.phone.officeLabel */ officeLabel: () => languagePack["contacts.card.phone.officeLabel"],
                /* contacts.card.phone.mobileLabel */ mobileLabel: () => languagePack["contacts.card.phone.mobileLabel"],
                /* contacts.card.phone.mainLabel */ mainLabel: () => languagePack["contacts.card.phone.mainLabel"]
            }
        },
        footer: {
            /* contacts.footer.emailMessage */ emailMessage: () => languagePack["contacts.footer.emailMessage"]
        },
        affiliations: {
            /* contacts.affiliations.title */ title: () => languagePack["contacts.affiliations.title"],
            type: {
                /* contacts.affiliations.type.member */ member: () => languagePack["contacts.affiliations.type.member"],
                /* contacts.affiliations.type.boardMember */ boardMember: () => languagePack["contacts.affiliations.type.boardMember"],
                /* contacts.affiliations.type.trustee */ trustee: () => languagePack["contacts.affiliations.type.trustee"],
                /* contacts.affiliations.type.other */ other: () => languagePack["contacts.affiliations.type.other"],
                /* contacts.affiliations.type.unknown */ unknown: () => languagePack["contacts.affiliations.type.unknown"]
            }
        }
    },
    company: {
        filters: {
            company: {
                /* company.filters.company.placeholder */ placeholder: () => languagePack["company.filters.company.placeholder"]
            }
        }
    },
    filters: {
        companyName: {
            /* filters.companyName.placeholder */ placeholder: () => languagePack["filters.companyName.placeholder"],
            /* filters.companyName.title */ title: () => languagePack["filters.companyName.title"],
            typeahead: {
                /* filters.companyName.typeahead.subtitle */ subtitle: () => languagePack["filters.companyName.typeahead.subtitle"],
                /* filters.companyName.typeahead.loading */ loading: () => languagePack["filters.companyName.typeahead.loading"],
                /* filters.companyName.typeahead.noResults */ noResults: () => languagePack["filters.companyName.typeahead.noResults"],
                /* filters.companyName.typeahead.hqLocation */ hqLocation: createFormat<any>(languagePack["filters.companyName.typeahead.hqLocation"], culture)
            }
        },
        contact: {
            typeahead: {
                /* filters.contact.typeahead.subtitle */ subtitle: () => languagePack["filters.contact.typeahead.subtitle"]
            }
        },
        location: {
            typeahead: {
                /* filters.location.typeahead.placeholder */ placeholder: () => languagePack["filters.location.typeahead.placeholder"],
                /* filters.location.typeahead.subtitle */ subtitle: () => languagePack["filters.location.typeahead.subtitle"]
            },
            sites: {
                typeahead: {
                    /* filters.location.sites.typeahead.subtitle */ subtitle: () => languagePack["filters.location.sites.typeahead.subtitle"]
                }
            }
        },
        specialite: {
            /* filters.specialite.title */ title: () => languagePack["filters.specialite.title"]
        },
        secteurFilter: {
            /* filters.secteurFilter.placeholder */ placeholder: () => languagePack["filters.secteurFilter.placeholder"],
            /* filters.secteurFilter.owner */ owner: () => languagePack["filters.secteurFilter.owner"],
            /* filters.secteurFilter.realEstate */ realEstate: () => languagePack["filters.secteurFilter.realEstate"],
            /* filters.secteurFilter.propertyManagement */ propertyManagement: () => languagePack["filters.secteurFilter.propertyManagement"],
            /* filters.secteurFilter.construction */ construction: () => languagePack["filters.secteurFilter.construction"],
            /* filters.secteurFilter.investmentBroker */ investmentBroker: () => languagePack["filters.secteurFilter.investmentBroker"]
        }
    },
    noData: {
        /* noData.actualites */ actualites: () => languagePack["noData.actualites"],
        /* noData.annuaire */ annuaire: () => languagePack["noData.annuaire"],
        /* noData.videos */ videos: () => languagePack["noData.videos"],
        /* noData.evenements */ evenements: () => languagePack["noData.evenements"],
        /* noData.formations */ formations: () => languagePack["noData.formations"],
        /* noData.title */ title: () => languagePack["noData.title"],
        /* noData.subtitle */ subtitle: () => languagePack["noData.subtitle"],
        /* noData.linksTitle */ linksTitle: () => languagePack["noData.linksTitle"],
        /* noData.noSearchCriteria */ noSearchCriteria: () => languagePack["noData.noSearchCriteria"]
    },
    phoneNumber: {
        /* phoneNumber.phoneNumber */ phoneNumber: createFormat<any>(languagePack["phoneNumber.phoneNumber"], culture),
        /* phoneNumber.phoneNumberExt */ phoneNumberExt: createFormat<any>(languagePack["phoneNumber.phoneNumberExt"], culture),
        /* phoneNumber.preferredNumber */ preferredNumber: createFormat<any>(languagePack["phoneNumber.preferredNumber"], culture),
        /* phoneNumber.preferredNumberExt */ preferredNumberExt: createFormat<any>(languagePack["phoneNumber.preferredNumberExt"], culture),
        /* phoneNumber.mobileNumber */ mobileNumber: createFormat<any>(languagePack["phoneNumber.mobileNumber"], culture),
        /* phoneNumber.mobileNumberExt */ mobileNumberExt: createFormat<any>(languagePack["phoneNumber.mobileNumberExt"], culture),
        /* phoneNumber.faxNumber */ faxNumber: createFormat<any>(languagePack["phoneNumber.faxNumber"], culture),
        /* phoneNumber.faxNumberExt */ faxNumberExt: createFormat<any>(languagePack["phoneNumber.faxNumberExt"], culture)
    },
    companies: {
        grid: {
            /* companies.grid.numberOfLocations */ numberOfLocations: () => languagePack["companies.grid.numberOfLocations"]
        },
        detail: {
            company: {
                /* companies.detail.company.locationWithCountryCodeAndSpeciality */ locationWithCountryCodeAndSpeciality: createFormat<any>(languagePack["companies.detail.company.locationWithCountryCodeAndSpeciality"], culture),
                /* companies.detail.company.locationWithCountryCode */ locationWithCountryCode: createFormat<any>(languagePack["companies.detail.company.locationWithCountryCode"], culture),
                /* companies.detail.company.location */ location: createFormat<any>(languagePack["companies.detail.company.location"], culture)
            },
            /* companies.detail.divider */ divider: () => languagePack["companies.detail.divider"]
        }
    },
    locations: {
        grid: {
            /* locations.grid.companies */ companies: () => languagePack["locations.grid.companies"],
            /* locations.grid.regions */ regions: () => languagePack["locations.grid.regions"],
            /* locations.grid.postalCode */ postalCode: () => languagePack["locations.grid.postalCode"]
        }
    },
    grid: {
        /* grid.name */ name: () => languagePack["grid.name"],
        /* grid.phone */ phone: () => languagePack["grid.phone"],
        /* grid.address */ address: () => languagePack["grid.address"],
        /* grid.city */ city: () => languagePack["grid.city"],
        /* grid.country */ country: () => languagePack["grid.country"],
        /* grid.sectorOfActivity */ sectorOfActivity: () => languagePack["grid.sectorOfActivity"],
        /* grid.companyType */ companyType: () => languagePack["grid.companyType"],
        /* grid.buildingName */ buildingName: () => languagePack["grid.buildingName"],
        /* grid.numberOfArticles */ numberOfArticles: () => languagePack["grid.numberOfArticles"],
        /* grid.numberOfSaleTransactions */ numberOfSaleTransactions: () => languagePack["grid.numberOfSaleTransactions"],
        /* grid.numberOfLeaseTransactions */ numberOfLeaseTransactions: () => languagePack["grid.numberOfLeaseTransactions"],
        /* grid.numberOfEmployees */ numberOfEmployees: () => languagePack["grid.numberOfEmployees"]
    },
    quickStats: {
        /* quickStats.parentCompanyLabel */ parentCompanyLabel: () => languagePack["quickStats.parentCompanyLabel"]
    },
    countFormatter: {
        /* countFormatter.collaborators */ collaborators: createFormat<any>(languagePack["countFormatter.collaborators"], culture),
        /* countFormatter.actualites */ actualites: createFormat<any>(languagePack["countFormatter.actualites"], culture)
    },
    /* interviewSectionHeading */ interviewSectionHeading: () => languagePack["interviewSectionHeading"],
    /* hotspotsSectionHeading */ hotspotsSectionHeading: () => languagePack["hotspotsSectionHeading"],
    /* rendezvousSectionHeading */ rendezvousSectionHeading: () => languagePack["rendezvousSectionHeading"],
    /* numberCompactShort */ numberCompactShort: createFormat<any>(languagePack["numberCompactShort"], culture),
    /* numberStandard */ numberStandard: createFormat<any>(languagePack["numberStandard"], culture),
    /* clearButtonLabel */ clearButtonLabel: () => languagePack["clearButtonLabel"],
    /* doneButtonLabel */ doneButtonLabel: () => languagePack["doneButtonLabel"],
    /* openFilters */ openFilters: () => languagePack["openFilters"],
    /* reportagesSectionHeading */ reportagesSectionHeading: () => languagePack["reportagesSectionHeading"],
    /* liveSessionSectionHeading */ liveSessionSectionHeading: () => languagePack["liveSessionSectionHeading"],
    /* notFoundPageTitle */ notFoundPageTitle: () => languagePack["notFoundPageTitle"],
    /* notFoundPageSubtitle */ notFoundPageSubtitle: () => languagePack["notFoundPageSubtitle"],
    email: {
        /* email.subjectMessage */ subjectMessage: () => languagePack["email.subjectMessage"]
    },
    /* sharingViolationTitle */ sharingViolationTitle: () => languagePack["sharingViolationTitle"],
    /* sharingViolationDescription */ sharingViolationDescription: () => languagePack["sharingViolationDescription"],
    /* timeOutTitle */ timeOutTitle: () => languagePack["timeOutTitle"],
    /* timeOutDescription */ timeOutDescription: () => languagePack["timeOutDescription"],
    /* globalTimeOutTitle */ globalTimeOutTitle: () => languagePack["globalTimeOutTitle"],
    /* okayGotItButton */ okayGotItButton: () => languagePack["okayGotItButton"],
    /* CompanyOnHoldTitle */ CompanyOnHoldTitle: () => languagePack["CompanyOnHoldTitle"],
    /* CompanyOnHoldDescription */ CompanyOnHoldDescription: createFormat<any>(languagePack["CompanyOnHoldDescription"], culture),
    /* defaultTitle */ defaultTitle: () => languagePack["defaultTitle"],
    /* defaultMessage */ defaultMessage: createFormat<any>(languagePack["defaultMessage"], culture),
    /* unAuthorizedDescription */ unAuthorizedDescription: () => languagePack["unAuthorizedDescription"],
    /* unAuthorizedTitle */ unAuthorizedTitle: () => languagePack["unAuthorizedTitle"],
    /* goToHomePage */ goToHomePage: () => languagePack["goToHomePage"],
    /* previous */ previous: () => languagePack["previous"],
    /* next */ next: () => languagePack["next"],
    /* cultureCode */ cultureCode: () => languagePack["cultureCode"]
})