export default {
    "areaPlaceholderWithNumber": "{uom, select, SF {{value, number, :: .} SF} AC {{value, number, :: .} AC} SM {{value, number, :: .} m²} HA {{value, number, :: .} ha} }",
    "imageAltText": "Image Alt Text",
    "interviewsLabel": "Interviews",
    "convenient": "Société",
    "subscription": "Abonnement",
    "advertisement": "Publicité",
    "whatAreWe": "À propos de nous",
    "legalMentions": "Mentions légales",
    "termsOfSales": "Conditions générales de vente",
    "privacyPolicy": "Avis mondial de confidentialité",
    "cookiePolicy": "Politique de cookies",
    "cookiePreferences": "Préférences cookies",
    "copyrightMessage": "© {year} CoStar Group. Tous droits réservés. En utilisant ce site, vous acceptez nos Conditions d’utilisation.",
    "news": "Actualités",
    "services": "Services",
    "editorial": "Édito",
    "investigations": "Enquêtes",
    "pointsDeVue": "Points de vue",
    "latestNewsLabel": "Fil d'actus",
    "viewMore": "Voir plus",
    "businessImmoTVAltText": "Go to Business Immo TV",
    "bigUpLogoAltText": "Go to Business Immo TV",
    "magazineAltText": "Go to Magazine",
    "magazineSectionHeading": "Le magazine",
    "magazineSectionViewMore": "Lire le magazine",
    "mostReadSectionHeading": "Les articles les plus lus",
    "moreNewsSectionHeading": "Plus d’actualités",
    "moreTransactionsNewsSectionHeading": "Plus de transactions",
    "allNewsButton": "Toute l'actualité",
    "allTransactionsNewsButton": "Toute les transactions",
    "setUpSectionHeading": "Business Immo TV s'installe au…",
    "latestVideosSectionHeading": "Dernières vidéos",
    "article.label.edito": "Édito",
    "article.label.magazine": "Magazine",
    "article.label.interview": "Interview",
    "article.label.notebook": "Carnet",
    "article.label.pointDeVue": "Point de vue",
    "article.label.bigUp": "Big UP",
    "article.label.portrait": "Portrait",
    "logIn": "Se connecter",
    "readAlso": "À lire aussi",
    "onTheSameTopic": "SUR LE MÊME THÈME",
    "navigation.allActualites": "Toutes les actualités",
    "navigation.allTransactions": "Toutes les transactions",
    "navigation.page.actualites": "Actualités",
    "navigation.page.bio": "Bio",
    "navigation.page.collaborateurs": "Collaborateurs",
    "navigation.page.affiliations": "Autres Fonctions",
    "navigation.page.annuaire": "Annuaire",
    "navigation.page.resume": "Résumé",
    "navigation.page.sites": "Sites",
    "navigation.page.bitv": "Business Immo TV",
    "navigation.page.transactions": "Transactions",
    "navigation.page.marketplace": "Marketplace",
    "navigation.news.category.evenements": "Événements",
    "navigation.news.category.formations": "Formations",
    "navigation.news.category.businessImmoSchool": "Business Immo School",
    "navigation.news.category.finance": "Finance",
    "navigation.news.category.carrieres": "Carrières",
    "navigation.news.category.esg": "ESG",
    "navigation.news.category.bureau": "Bureau",
    "navigation.news.category.commerce": "Commerce",
    "navigation.news.category.hotellerie": "Hôtellerie",
    "navigation.news.category.logement": "Logement",
    "navigation.news.category.logistique": "Logistique",
    "navigation.news.category.foncier": "Foncier",
    "navigation.news.category.alternative": "Alternative",
    "navigation.news.category.architecture": "Architecture",
    "navigation.news.category.designAndBuild": "Design & Build",
    "navigation.transactions.category.bureau": "Transactions Bureau",
    "navigation.transactions.category.commerce": "Transactions Commerce",
    "navigation.transactions.category.hotellerie": "Transactions Hôtellerie",
    "navigation.transactions.category.logement": "Transactions Logement",
    "navigation.transactions.category.logistique": "Transactions Logistique",
    "navigation.transactions.category.foncier": "Transactions Foncier",
    "navigation.transactions.category.alternative": "Transactions Alternative",
    "navigation.group.name.servicesBusinessImmo": "Services Business Immo",
    "navigation.group.name.monCompte": "Mon compte",
    "navigation.link.informationsPersonnelles": "Informations personnelles",
    "navigation.link.motDePasse": "Mot de passe",
    "navigation.link.accountManager": "Account Manager",
    "navigation.link.depechesEtNewsletters": "Dépêches et newsletters",
    "navigation.link.deconnexion": "Déconnexion",
    "navigation.header.link.sabonner": "S'abonner",
    "navigation.professionals.professionnels": "Professionnels",
    "navigation.professionals.societes": "Sociétés",
    "navigation.loopnet.linkText": "Trouver vos futurs locaux",
    "navigation.header.search.placeholder.actualites": "Rechercher par mot clé",
    "navigation.header.search.placeholder.annuaire": "Rechercher par nom",
    "navigation.header.search.placeholder.societes": "Rechercher par nom ou activité",
    "article.relatedLinks.header": "LIENS CONNEXES",
    "article.relatedLinks.properties": "Propriété",
    "article.relatedLinks.markets": "Marchés",
    "article.relatedLinks.companiesCited": "Sociétés citées",
    "article.relatedLinks.personsCited": "Personnes citées",
    "article.relatedLinks.fileAttachments": "Documents associés",
    "navigation.header.search.buttonDescription": "Bouton de recherche",
    "article.author.bylineBy": "Par",
    "upsell.login.banner.text": "Cet article est reserve aux abonnes Business Immo",
    "upsell.header.corporateSubscriber": "L’abonnement Business Immo c’est :",
    "upsell.header.informationRequest": "Demande d'information",
    "upsell.body.headerTwo": "Avantages exclusifs",
    "upsell.body.headerThree": "Un accès illimité à toute l’actualité de l’industrie immobilière",
    "upsell.body.textOne": "Des contenus à 360°\n2 080 articles premium en 2023\n1 200 transactions révélées en 2023\n+29 000 de fiches contact dans notre annuaire",
    "upsell.body.textTwo": "Profitez d’avantages exclusifs tout au long de l’année avec des offres sur-mesure et des prix dégressifs en fonction du nombre de licences souhaitées.",
    "upsell.body.textThree": "Grâce à votre licence Business Immo, profitez des dernières actualités du marché en temps réel",
    "upsell.body.textFour": "Notre équipe aura le plaisir de vous contacter rapidement afin de vous faire découvrir l’ensemble de nos avantages.",
    "upsell.body.learnMore": "En savoir plus",
    "upsell.form.submit.thankYou": "Merci !",
    "upsell.form.submit.body": "Un expert de votre marché vous contactera pour comprendre les besoins de votre entreprise et vous présenter une démonstration personnalisée de notre offre d'abonnement.",
    "upsell.form.submit.errorText": "Désolé, un problème est survenu avec le formulaire. Veuillez réessayer.",
    "upsell.form.input.placeholder.companyName": "Nom de la société",
    "upsell.form.input.placeholder.firstName": "Prénom",
    "upsell.form.input.placeholder.lastName": "Nom",
    "upsell.form.input.placeholder.emailAddress": "Email professionnel",
    "upsell.form.input.placeholder.phoneNumber": "Téléphone",
    "upsell.form.input.placeholder.postalCode": "Code postal",
    "upsell.form.dropdown.placeholder.selectIndustry": "Secteur d’activité",
    "upsell.form.dropdown.option.lender": "Bailleur",
    "upsell.form.dropdown.option.multifamilyPropertyManager": "Property Manager résidentiel",
    "upsell.form.dropdown.option.broker": "Broker",
    "upsell.form.dropdown.option.retailer": "Enseigne",
    "upsell.form.dropdown.option.serviceProvider": "Facility Manager",
    "upsell.form.dropdown.option.ownerAndDeveloper": "Propriétaire – Promoteur-développeur",
    "upsell.form.dropdown.option.appraiser": "Expert – Conseil",
    "upsell.form.button.submit": "Envoyer",
    "common.error.problem": "Il y a un problème.",
    "common.error.info": "Oups ! Une erreur s’est produite. Veuillez réessayer plus tard.",
    "common.error.tryAgain": "Réessayez !",
    "common.error500Msg.heading": "Erreur interne du serveur",
    "common.error500Msg.info": "Le serveur a rencontré une erreur et n’a pas pu exécuter votre requête.",
    "common.error500Msg.tryANewSearch": "Essayez une nouvelle recherche.",
    "common.typeaheadFuzzySearch": "{searchQuery} - Recherche",
    "article.shareIcon.facebook.title": "Share on Facebook",
    "article.shareIcon.twitter.title": "Share on Twitter",
    "article.shareIcon.linkedin.title": "Share on LinkedIn",
    "article.shareIcon.email.title": "Email article",
    "search.results.label": "{resultCount} actualités pour :",
    "search.thematiques.label": "Thématiques",
    "search.thematiques.finance": "Finance",
    "search.thematiques.carrieres": "Carrières",
    "search.thematiques.ESG": "ESG",
    "search.thematiques.bureau": "Bureau",
    "search.thematiques.commerce": "Commerce",
    "search.thematiques.hotellerie": "Hôtellerie",
    "search.thematiques.logement": "Logement",
    "search.thematiques.logistique": "Logistique",
    "search.thematiques.foncier": "Foncier",
    "search.thematiques.alternative": "Alternative",
    "search.thematiques.architecture": "Architecture",
    "search.thematiques.designAndBuild": "Design & Build",
    "search.thematiques.location": "Location",
    "search.thematiques.vente": "Vente",
    "search.thematiques.video": "Vidéo",
    "search.categories.label": "Catégories",
    "search.categories.transaction": "Transaction",
    "search.categories.interview": "Interview",
    "search.categories.portrait": "Portrait",
    "search.categories.edito": "Édito",
    "search.categories.pointDeVue": "Point de vue",
    "search.categories.carnet": "Carnet",
    "search.date.label": "Date",
    "search.date.placeholder": "Sélection",
    "search.date.dividerText": "ou",
    "search.date.dropdown.label": "Publié depuis",
    "search.date.dropdown.threeMonths": "3 mois",
    "search.date.dropdown.sixMonths": "6 mois",
    "search.date.dropdown.oneYear": "1 an",
    "search.date.dropdown.twoYears": "2 ans",
    "search.date.dropdown.threeYears": "3 ans",
    "search.date.dropdown.fourYears": "4 ans",
    "search.date.dropdown.fiveYears": "5 ans",
    "search.date.dropdown.tenYears": "10 ans",
    "search.date.before": "Avant",
    "search.date.after": "Après",
    "search.clear": "Effacer",
    "search.searchCards.clickHere": "Cliquez ici",
    "search.searchCards.foundInDirectory": "Trouvés dans notre annuaire.",
    "search.searchCards.noAuth": "Vous recherchez une personne ou une société?",
    "search.searchCards.noResults": "Aucun contact & société",
    "modal.OK": "J'accepte",
    "cookieModal.title": "fenetre de politique de cookies",
    "privatePolicyModal.title": "fenetre de avis mondial de confidentialité",
    "legalModal.title": "fenetre de mentions légales",
    "cgv.title": "fenetre de conditions generales de vente",
    "directory.contactCount": "{contacts, number} {contacts, plural, one {professionnel} other {professionnels}}",
    "contacts.grid.company": "Société",
    "contacts.grid.title": "Titre",
    "contacts.grid.contactInfo": "Contact",
    "contacts.filters.contact.placeholder": "Rechercher un professionnel",
    "company.filters.company.placeholder": "Rechercher une société",
    "filters.companyName.placeholder": "Société",
    "filters.companyName.title": "Company",
    "filters.companyName.typeahead.subtitle": "Saisir un nom de société",
    "filters.companyName.typeahead.loading": "En cours de chargement...",
    "filters.companyName.typeahead.noResults": "Aucun résultat trouvé",
    "filters.companyName.typeahead.hqLocation": "HQ {address}",
    "filters.contact.typeahead.subtitle": "Saisir un nom de professionnel",
    "filters.location.typeahead.placeholder": "Lieu",
    "filters.location.typeahead.subtitle": "Saisir le marché, la ville ou le code postal",
    "filters.location.sites.typeahead.subtitle": "Implantation du bureau",
    "filters.specialite.title": "Spécialité",
    "filters.secteurFilter.placeholder": "Secteur",
    "filters.secteurFilter.owner": "Propriétaire",
    "filters.secteurFilter.realEstate": "Conseil en immobilier",
    "filters.secteurFilter.propertyManagement": "Property Management",
    "filters.secteurFilter.construction": "Construction",
    "filters.secteurFilter.investmentBroker": "Investment Broker",
    "contacts.card.phone.officeLabel": "Standard",
    "contacts.card.phone.mobileLabel": "Mobile",
    "contacts.card.phone.mainLabel": "Ligne directe",
    "contacts.footer.emailMessage": "Signaler une erreur",
    "search.filters": "Filtres",
    "noData.actualites": "L`actualité",
    "noData.annuaire": "L`annuaire",
    "noData.videos": "Les vidéos",
    "noData.evenements": "Nos événements",
    "noData.formations": "Nos formations",
    "noData.title": "Désolé",
    "noData.subtitle": "Aucune information disponible",
    "noData.linksTitle": "Voici quelques liens utiles :",
    "noData.noSearchCriteria": "Vous n'avez pas sélectionné de critères de recherche. Veuillez ajouter des critères pour voir les résultats.",
    "upsell.contactSearchBanner": "L’annuaire est réservé aux abonnés Business Immo",
    "upsell.contactSearchFormDescription": "Notre équipe aura le plaisir de vous contacter rapidement afin de vous faire découvrir l’ensemble de nos avantages.",
    "upsell.contactSearchColumn1Title": "Notre annuaire professionnel dans l'immobilier d'entreprise est bien plus qu'une simple liste de contacts.",
    "upsell.contactSearchColumn1Body": "En vous abonnant à Business Immo, vous accédez à un vaste réseau de professionnels de l'immobilier d'entreprise, des experts chevronnés aux talents émergents. Vous serez en contact avec les acteurs clés du secteur, ouvrant ainsi les portes de collaborations fructueuses.",
    "phoneNumber.phoneNumber": "{number}",
    "phoneNumber.phoneNumberExt": "{number} X{extension}",
    "phoneNumber.preferredNumber": "{number} (p)",
    "phoneNumber.preferredNumberExt": "{number} X{extension} (p)",
    "phoneNumber.mobileNumber": "{number} (m)",
    "phoneNumber.mobileNumberExt": "{number} X{extension} (m)",
    "phoneNumber.faxNumber": "{number} (f)",
    "phoneNumber.faxNumberExt": "{number} X{extension} (f)",
    "companies.grid.numberOfLocations": "Sites",
    "directory.companyCount": "{companies, number} {companies, plural, one {société} other {sociétés}}",
    "locations.grid.companies": "Sociétés",
    "locations.grid.regions": "Régions",
    "locations.grid.postalCode": "Code postal",
    "grid.name": "Nom",
    "grid.phone": "Téléphone",
    "grid.address": "Adresse",
    "grid.city": "Ville",
    "grid.country": "Pays",
    "grid.sectorOfActivity": "Spécialité",
    "grid.companyType": "Type de société",
    "grid.buildingName": "Nom de l’immeuble",
    "grid.numberOfArticles": "Articles",
    "grid.numberOfSaleTransactions": "Transactions ventes (3 ans)",
    "grid.numberOfLeaseTransactions": "Transactions locations (3 ans)",
    "grid.numberOfEmployees": "Employés",
    "contacts.affiliations.title": "Autres Fonctions",
    "contacts.affiliations.type.member": "Membre",
    "contacts.affiliations.type.boardMember": "Membre d'équipage",
    "contacts.affiliations.type.trustee": "Curateur",
    "contacts.affiliations.type.other": "Autre",
    "contacts.affiliations.type.unknown": "Inconnu",
    "quickStats.parentCompanyLabel": "Société parent",
    "countFormatter.collaborators": "1 - {visibleItems, number} de {totalItems, number} {totalItems, plural, one {collaborateur} other {collaborateurs}}",
    "countFormatter.actualites": "1 - {visibleItems, number} de {totalItems, number} {totalItems, plural, one {actualite} other {actualites}}",
    "companies.detail.company.locationWithCountryCodeAndSpeciality": "{speciality} - {companyCityStateAddress} ({companyCountryCode})",
    "companies.detail.company.locationWithCountryCode": "{companyCityStateAddress} ({companyCountryCode})",
    "companies.detail.company.location": "{companyCityStateAddress}",
    "companies.detail.divider": " | ",
    "interviewSectionHeading": "Interviews",
    "hotspotsSectionHeading": "Hotspots",
    "rendezvousSectionHeading": "Rendez-vous avec",
    "numberCompactShort": "{num, number, :: compact-short}",
    "numberStandard": "{num, number}",
    "clearButtonLabel": "Effacer",
    "doneButtonLabel": "Terminé",
    "openFilters": "Ouvrir les filtres",
    "reportagesSectionHeading": "Reportages",
    "liveSessionSectionHeading": "Live Session",
    "notFoundPageTitle": "Nous n'avons pas pu traiter votre demande.",
    "notFoundPageSubtitle": "Veuillez effectuer une nouvelle recherche",
    "email.subjectMessage": "Correction de l'annuaire",
    "sharingViolationTitle": "Compte en cours d'utilisation",
    "sharingViolationDescription": "Une autre session utilisant votre compte a été détectée. Vous devrez vous reconnecter plus tard.",
    "timeOutTitle": "Durée d’activité maximale dépassée",
    "timeOutDescription": "Votre session actuelle a expiré. Vous devrez vous connecter à nouveau.",
    "globalTimeOutTitle": "Délai global",
    "okayGotItButton": "D’accord, j’ai compris",
    "CompanyOnHoldTitle": "Compte de la société en attente",
    "CompanyOnHoldDescription": "Impossible d’ouvrir une session. Veuillez contacter Business Immo au {phoneNum} pour supprimer le statut « En attente ».",
    "defaultTitle": "Erreur d’authentification",
    "defaultMessage": "Erreur d’authentification. Veuillez contacter Business Immo au {phoneNum}.",
    "unAuthorizedDescription": "Votre compte n'est pas autorisé à accéder à ce contenu.",
    "unAuthorizedTitle": "Accès non autorisé",
    "goToHomePage": "Aller à la page d’accueil",
    "previous": "Précédent",
    "next": "Suivant",
    "cultureCode": "fr-FR"
}